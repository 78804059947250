import React, { useState } from "react";
import { EditorState } from "draft-js";

import { RichTextArea, richTextFromHTML, richTextToHTML } from "../FormInput";
import { StoreAction } from "./store";

interface EditDescriptionFieldProps {
  field: Form.Field;
  closeModal: (evt: React.MouseEvent) => void;
  dispatch: (action: StoreAction) => void;
}

interface EditDescriptionFieldState {
  description: EditorState;
}

export default function EditDescriptionField(props: EditDescriptionFieldProps) {
  const [state, setState] = useState<EditDescriptionFieldState>({
    description: richTextFromHTML(props.field.description)
  });

  const updateState = (newState: Partial<EditDescriptionFieldState>) => {
    setState({ ...state, ...newState });
  };

  const save = (evt: React.MouseEvent) => {
    props.dispatch({
      type: "updateField",
      payload: {
        ...props.field,
        description: richTextToHTML(state.description)
      }
    });
    props.closeModal(evt);
  };

  return (
    <form className="modal-form">
      <h2>Endre felt</h2>
      <div className="row">
        <RichTextArea
          name="description"
          label="Beskrivelse"
          onChange={(val: EditorState) => updateState({ description: val })}
          value={state.description}
          links={true}
          simple={true}
          size={12}
        />
      </div>
      <div className="buttons">
        <button type="submit" onClick={save}>
          Oppdater
        </button>
        <button onClick={props.closeModal}>Avbryt</button>
      </div>
    </form>
  );
}
