import React from "react";
import { attachmentPath } from "../lib/attachments";

interface Props {
  attachments?: Attachment[];
  submission?: Submission;
}

export default function Attachments(props: Props) {
  const { submission } = props;
  const attachments = props.attachments || [];

  if (attachments.length == 0 && !submission) {
    return "";
  }

  return (
    <div className="attachments">
      <ul>
        {submission && (
          <React.Fragment>
            <li key={submission.url}>
              <i className="material-icons">attachment</i>
              <a href={submission.url}>{submission.name}</a>
            </li>
            {submission.signed_url && (
              <li
                key={submission.signed_url}
                className={submission.signed_pending ? "pending" : undefined}>
                <i className="material-icons">attachment</i>
                {submission.signed_pending ? (
                  <React.Fragment>{submission.name}.pdf</React.Fragment>
                ) : (
                  <React.Fragment>
                    <a href={submission.signed_url}>{submission.name}.pdf</a>
                    <span className="label-tag signed">Signert</span>
                  </React.Fragment>
                )}
              </li>
            )}
          </React.Fragment>
        )}
        {attachments.map((attachment) => (
          <li key={attachment.id}>
            <i className="material-icons">attachment</i>
            <a
              href={attachmentPath(attachment)}
              target="_blank"
              rel="noreferrer">
              {attachment.filename}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
