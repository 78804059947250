import React from "react";

import Sent from "./Sent";
import Tab from "./Tab";
import Tabs from "./Tabs";
import { CaseFormState, CaseFormDispatch } from "./useCaseForm";

interface Props {
  state: CaseFormState;
  dispatch: CaseFormDispatch;
  preview: boolean;
}

export default function Form(props: Props) {
  const { state, dispatch } = props;
  const { form } = state;

  if (state.submitted) {
    return <Sent />;
  }

  if (state.summary) {
    return (
      <React.Fragment>
        {form.tabs.map((t, i) => (
          <Tab key={i} tab={t} {...props} />
        ))}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Tabs state={state} dispatch={dispatch} preview={props.preview} />
      <Tab tab={form.tabs[state.currentTab]} {...props} />
    </React.Fragment>
  );
}
