import React from "react";

import ReportForm from "./ReportForm";

interface Props {
  report: Report.Report;
}

export default function ShowReport(props: Props) {
  return (
    <ReportForm
      report={props.report}
      attributes={props.report.data}
      locked={true}
    />
  );
}
