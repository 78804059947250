import React, { ChangeEvent } from "react";

interface SearchProps {
  search: string;
  updateSearch: (query: string) => void;
}

export default function Search(props: SearchProps) {
  const updateSearch = (evt: ChangeEvent<HTMLInputElement>) => {
    props.updateSearch(evt.target.value);
  };

  return (
    <div className="search-bar">
      <form>
        <input
          className="search"
          placeholder="Søk i søknader"
          onChange={updateSearch}
          value={props.search}
        />
      </form>
    </div>
  );
}
