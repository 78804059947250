import React from "react";

import {
  EditorState,
  ContentState,
  RawDraftContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import Error from "./Error";
import Field from "./Field";
import Label from "./Label";
import RichEditor, { decorator } from "../RichEditor/RichEditor";
import useInput, { nameToId } from "./useInput";

export function richTextFromHTML(str?: string) {
  const blocksFromHTML = convertFromHTML(str || "");
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(state, decorator);
}

export function richTextToHTML(state: EditorState) {
  if (!state.getCurrentContent().hasText()) {
    return "";
  }

  const options = {
    inlineStyles: {
      BOLD: { element: "b" },
      ITALIC: { element: "i" },
      RED: { element: "span", attributes: { class: "red" } }
    }
  };
  return stateToHTML(state.getCurrentContent(), options);
}

function createEditorState(value: RawDraftContentState) {
  if (value && Object.keys(value).length > 0) {
    return EditorState.createWithContent(convertFromRaw(value), decorator);
  } else {
    return EditorState.createEmpty(decorator);
  }
}

function rawContents(editorState: EditorState) {
  return JSON.stringify(convertToRaw(editorState.getCurrentContent()));
}

export default function RichTextArea(props: FormInput.RichTextArea) {
  const { label, name } = props;

  const initializer = (): EditorState => {
    if ("onChange" in props) {
      return props.value;
    } else {
      return createEditorState(props.value);
    }
  };

  const { value, setValue, error } = useInput<
    FormInput.RichTextArea,
    EditorState
  >(props, initializer);

  const handleChange = (editorState: EditorState) => {
    setValue(editorState);
  };

  return (
    <Field type="textarea rich-textarea" size={props.size}>
      <Label htmlFor={nameToId(name)} label={`${label}:`} />
      <input type="hidden" name={name} value={rawContents(value)} />
      <RichEditor
        editorState={value}
        onChange={handleChange}
        readOnly={props.disabled}
        links={props.links}
        simple={props.simple}
      />
      <Error error={error} />
    </Field>
  );
}
