import React, { useEffect, useState, ChangeEvent, RefObject } from "react";

import Error from "./Error";
import Field from "./Field";
import Label from "./Label";
import useInput, { nameToId } from "./useInput";

function useWindowWidth() {
  let initialWidth = 0;
  if (typeof window !== "undefined" && window && "innerWidth" in window) {
    initialWidth = window.innerWidth;
  }

  const [width, setWidth] = useState(initialWidth);

  const updateWidth = () => {
    const newWidth = window.innerWidth;
    if (newWidth != width) {
      setWidth(newWidth);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
    }
  });

  return width;
}

function useScrollHeight(value: string, ref: RefObject<HTMLElement>) {
  const [height, setHeight] = useState(10);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (typeof window !== "undefined" && ref.current) {
      const elem = ref.current;
      const scrollPosition = window.scrollY;
      const prevHeight = elem.style.height;
      let newHeight = 0;

      elem.style.height = "10px";
      newHeight = elem.scrollHeight + 4;
      elem.style.height = prevHeight;

      // Restore scroll position
      window.scroll(0, scrollPosition);

      if (newHeight != height) {
        setHeight(newHeight);
      }
    }
  }, [value, windowWidth, height, ref]);

  return height;
}

export default function TextArea(props: FormInput.TextArea) {
  const { label, name, readonly } = props;

  const { value, setValue, listeners, error } =
    useInput<FormInput.TextArea>(props);

  const height = useScrollHeight(value, listeners.ref);

  const handleChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(evt.target.value);
  };

  return (
    <Field type="textarea" size={props.size}>
      <Label
        htmlFor={nameToId(name)}
        label={`${label}:`}
        tooltip={props.tooltip}
      />
      {!readonly && (
        <textarea
          aria-label={props.ariaLabel}
          autoFocus={props.autoFocus}
          id={nameToId(name)}
          disabled={props.disabled}
          placeholder={props.placeholder}
          name={name}
          className={error ? "warn" : ""}
          value={value}
          readOnly={props.readonly}
          style={{ height: `${height}px` }}
          onChange={handleChange}
          {...listeners}
          ref={listeners.ref as React.RefObject<HTMLTextAreaElement>}
        />
      )}
      {readonly && (
        <div
          id={nameToId(name)}
          className={"text-area " + (error ? "warn" : "")}>
          {value.split("\n").map((line, key) => (
            <span key={key}>
              {line}
              <br />
            </span>
          ))}
        </div>
      )}
      <Error error={error} />
    </Field>
  );
}
