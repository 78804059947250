import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

export default function ButtonSection(props: Props) {
  return (
    <section>
      <div className="inner">
        <div className="buttons">{props.children}</div>
      </div>
    </section>
  );
}
