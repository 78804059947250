import React from "react";

import { TextField } from "../FormInput";

interface Props {
  recommendation: MeetingCase.Recommendation;
  reviews: MeetingCase.Review[];
}

function blankBlock() {
  return (
    <div className="block">
      <p>
        <i>Blank</i>
      </p>
    </div>
  );
}

function formattedText(str: string) {
  if (str) {
    return <div className="block" dangerouslySetInnerHTML={{ __html: str }} />;
  } else {
    return blankBlock();
  }
}

function renderReview(review: MeetingCase.Review) {
  return (
    <React.Fragment key={review.id}>
      {formattedText(review.body)}
    </React.Fragment>
  );
}

export default function Recommendation(props: Props) {
  const { reviews } = props;

  const {
    total_cost,
    grant_sum,
    deductible,
    excerpt,
    recommendation,
    proposed_decision,
    previous_decisions
  } = props.recommendation;

  return (
    <div className="recommendation">
      <h4>Anbefaling</h4>
      {formattedText(recommendation)}
      <h4>Forslag til vedtak</h4>
      <form>
        <div className="row">
          <TextField
            label="Kostnadsramme"
            name="total_cost"
            value={total_cost ? `${total_cost}` : ""}
            disabled={true}
            validations={["decimal"]}
          />
          <TextField
            label="Tilskuddsramme"
            name="grant_sum"
            value={grant_sum ? `${grant_sum}` : ""}
            disabled={true}
            validations={["decimal"]}
          />
          <TextField
            label="Egenandel (%)"
            name="deductible"
            value={deductible ? `${deductible}` : ""}
            disabled={true}
            validations={["decimal"]}
          />
        </div>
      </form>
      {formattedText(proposed_decision)}
      <h4>Kopi av søknad</h4>
      {formattedText(excerpt)}
      <h4>Faglig vurdering</h4>
      {reviews.length > 0 ? reviews.map((r) => renderReview(r)) : blankBlock()}
      <h4>Tidligere vedtak</h4>
      {formattedText(previous_decisions)}
    </div>
  );
}
