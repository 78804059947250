import React from "react";
import Attachments from "../Attachments";

interface Props {
  cost: Report.Cost;
  disabled: boolean;
  locked: boolean;
  handleEdit: (evt: React.MouseEvent) => void;
  handleRemove: (evt: React.MouseEvent) => void;
}

const formatNumber = (num: number) => {
  return new Intl.NumberFormat("nb-NO", { style: "decimal" }).format(num);
};

export default function CostItem(props: Props) {
  const { cost, locked, handleEdit, handleRemove } = props;
  const disabled = props.disabled || locked;

  const attachments = (cost.invoice_files || []).concat(
    cost.receipt_files || []
  );

  return (
    <div className="cost-item">
      <div className="name">{cost.name}</div>
      <div className="sum">{formatNumber(cost.sum)}</div>
      {!locked && (
        <div className="actions">
          <button type="button" disabled={disabled} onClick={handleEdit}>
            Endre
          </button>
          <button type="button" disabled={disabled} onClick={handleRemove}>
            Fjern
          </button>
        </div>
      )}
      {locked && <Attachments attachments={attachments} />}
    </div>
  );
}
