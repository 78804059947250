import React from "react";

import useToastStore from "../../stores/useToastStore";
import { CaseFormState, CaseFormDispatch } from "./useCaseForm";
import { save, submit, tabPath } from "./persistence";

interface Props {
  state: CaseFormState;
  dispatch: CaseFormDispatch;
  invalid: Form.Field[];
  tab: Form.Tab;
  preview: boolean;
}

export default function Buttons(props: Props) {
  const { tab, state, dispatch } = props;
  const tabs = state.form.tabs;
  const nextTab = tabs[tabs.indexOf(tab) + 1];
  const { error, notice } = useToastStore((state) => state);

  const handleNextTab = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "gotoNextTab" });
  };

  const handleError = (err: Error) => {
    error("En feil oppsto ved lagring");
    throw err;
  };

  const success = () => {
    notice("Dine endringer ble lagret");
  };

  const handleSave = (evt: React.MouseEvent) => {
    evt.preventDefault();
    save(state, dispatch, success, handleError);
  };

  const handleSubmit = (evt: React.MouseEvent) => {
    evt.preventDefault();
    submit(state, dispatch, success, handleError);
  };

  if (tab.submit) {
    return (
      <div className="buttons">
        <button
          type="submit"
          onClick={handleSubmit}
          disabled={props.invalid.length > 0}>
          <i className="material-icons error-icon">send</i>
          Send inn søknad
        </button>
      </div>
    );
  } else {
    return (
      <div className="buttons">
        <button type="submit" onClick={handleSave} disabled={state.saved}>
          Lagre
        </button>
        {nextTab && (
          <a
            href={tabPath(state, nextTab, props.preview)}
            onClick={handleNextTab}>
            Gå til neste side
          </a>
        )}
      </div>
    );
  }
}
