import React from "react";

import TabLink from "./TabLink";

interface TabsProps {
  tabs: Recommendation.InterfaceTab[];
  tab: Recommendation.InterfaceTab;
  setTab: (tab: Recommendation.InterfaceTab) => void;
}

export default function Tabs(props: TabsProps) {
  const changeTab = (tab: Recommendation.InterfaceTab) => () => {
    props.setTab(tab);
  };

  return (
    <nav className="subnav tabs">
      <div className="inner">
        {props.tabs.map((t) => (
          <TabLink
            key={t.id}
            tab={t}
            current={t.id == props.tab.id}
            onClick={changeTab(t)}
          />
        ))}
      </div>
    </nav>
  );
}
