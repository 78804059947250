import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import Modal from "../Modal";
import CostForm from "./CostForm";
import CostItem from "./CostItem";

interface Props {
  caseId: number;
  costs: Report.Cost[];
  onChange: (costs: Report.Cost[]) => void;
  disabled: boolean;
  locked: boolean;
  receiptRequired: boolean;
  strings: Record<string, string>;
}

function newCost(): Report.Cost {
  return {
    id: uuidv4(),
    name: "",
    sum: null,
    invoice_files: [],
    receipt_files: []
  };
}

export default function Costs(props: Props) {
  const { caseId, costs, onChange, locked, receiptRequired, strings } = props;
  const disabled = props.disabled || locked;

  const [editing, setEditing] = useState<Report.Cost | null>();

  const closeModal = (evt?: React.MouseEvent) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    setEditing(null);
  };

  const updateCost = (cost: Report.Cost) => {
    if (costs.map((c) => c.id).indexOf(cost.id) !== -1) {
      onChange(costs.map((c) => (c.id === cost.id ? cost : c)));
    } else {
      onChange([...costs, cost]);
    }
  };

  const handleAdd = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setEditing(newCost());
  };

  const handleEdit = (cost: Report.Cost) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    setEditing(cost);
  };

  const handleRemove = (cost: Report.Cost) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (confirm("Er du sikker på at du vil fjerne denne kostnaden?")) {
      onChange(costs.filter((c) => c.id !== cost.id));
    }
  };

  return (
    <div className="costs">
      {editing && (
        <Modal onClose={closeModal}>
          <CostForm
            caseId={caseId}
            cost={editing}
            closeModal={closeModal}
            updateCost={updateCost}
            receiptRequired={receiptRequired}
            strings={strings}
          />
        </Modal>
      )}
      <div className="costs-list">
        {costs.map((c) => (
          <CostItem
            key={c.id}
            cost={c}
            disabled={disabled}
            locked={locked}
            handleEdit={handleEdit(c)}
            handleRemove={handleRemove(c)}
          />
        ))}
      </div>
      {!locked && (
        <div className="bottom-row">
          <button onClick={handleAdd} disabled={disabled}>
            <i className="material-icons">add</i>
            Legg til kostnad
          </button>
        </div>
      )}
    </div>
  );
}
