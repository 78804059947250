import React, { ChangeEvent, useState } from "react";

import Error from "./Error";
import Field from "./Field";
import Label from "./Label";
import VisibilityToggle from "./VisibilityToggle";
import useInput, {
  formattedValue,
  nameToId,
  textFieldInitializer
} from "./useInput";

export default function TextField(props: FormInput.TextField) {
  const { description, label, name, validations } = props;

  const [visible, setVisible] = useState(props.type !== "password");
  const { value, setValue, listeners, error } = useInput<FormInput.TextField>(
    props,
    textFieldInitializer(props)
  );

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
  };

  const toggleVisible = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    evt.preventDefault();
    setVisible(!visible);
    listeners.ref.current.focus();
  };

  let type = props.type || "text";
  if (type == "password" && visible) {
    type = "text";
  }

  const classNames = [];
  if (!label) {
    classNames.push("no-label");
  }
  if (error) {
    classNames.push("warn");
  }

  return (
    <Field type="text" size={props.size}>
      {label && (
        <Label
          htmlFor={nameToId(name)}
          label={`${label}:`}
          tooltip={props.tooltip}
        />
      )}
      <input
        aria-label={props.ariaLabel}
        autoFocus={props.autoFocus}
        id={nameToId(name)}
        disabled={props.disabled}
        placeholder={props.placeholder}
        type={type}
        name={name}
        className={classNames.join(" ")}
        value={formattedValue(value, validations)}
        onChange={handleChange}
        readOnly={props.readonly}
        {...listeners}
        ref={listeners.ref as React.RefObject<HTMLInputElement>}
      />
      {props.type == "password" && (
        <VisibilityToggle visible={visible} onClick={toggleVisible} />
      )}
      <Error error={error} />
      {description && !error && (
        <div className="description">{description}</div>
      )}
    </Field>
  );
}
