import React from "react";

import { csrfToken, xhr } from "../../lib/request";
import ReportForm from "./ReportForm";

interface Props {
  accept_url: string;
  report: Report.Report;
}

export default function AcceptReport(props: Props) {
  const handleAccept = (evt: React.MouseEvent) => {
    evt.preventDefault();
    xhr(
      props.accept_url,
      { method: "POST", authToken: csrfToken() },
      (response: Report.BackendResponse) => {
        if (response.redirect) {
          window.location.assign(response.redirect);
        }
      }
    ).send();
  };

  return (
    <ReportForm
      report={props.report}
      attributes={props.report.data}
      locked={true}>
      <section>
        <div className="inner">
          {props.report.accepted && <p>Rapporten er godkjent.</p>}
          {!props.report.accepted && (
            <div className="buttons">
              <button className="primary" onClick={handleAccept}>
                Godkjenn
              </button>
            </div>
          )}
        </div>
      </section>
    </ReportForm>
  );
}
