import React from "react";

interface NavProps {
  changeTab: (tabId: string) => void;
  currentTab: Form.Tab;
  tabs: Form.Tab[];
}

export default function Nav(props: NavProps) {
  const selectTab = (tab: Form.Tab) => (evt: React.MouseEvent) => {
    evt.preventDefault();
    props.changeTab(tab._id);
  };

  const tabItem = (tab: Form.Tab) => {
    const classes = ["nav-item"];
    if (tab._id == props.currentTab._id) {
      classes.push("current");
    }
    return (
      <a
        className={classes.join(" ")}
        href="#"
        key={tab._id}
        onClick={selectTab(tab)}>
        {tab.name}
      </a>
    );
  };

  return (
    <nav className="subnav tabs">
      <div className="inner">{props.tabs.map((tab) => tabItem(tab))}</div>
    </nav>
  );
}
