import React from "react";
import {
  CheckBox,
  Radio,
  RichTextArea,
  TextArea,
  TextField,
  stringOption
} from "../FormInput";
import Tab from "./Tab";

interface FormProps {
  locked: boolean;
  priorities: Priority[];
  attributes: Recommendation.Attributes;
  updateAttribute: (attr: string) => (value: unknown) => void;
  tab: Recommendation.InterfaceTab;
  reviews: Recommendation.Review[];
  deleteRecommendation: () => void;
}

export default function Form(props: FormProps) {
  const { attributes, tab, updateAttribute, reviews, locked } = props;

  const handleDelete = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (confirm("Er du sikker på at du vil slette denne innstillingen?")) {
      props.deleteRecommendation();
    }
  };

  return (
    <section>
      <div className="inner">
        <Tab id="notes" tab={tab}>
          <div className="row">
            <RichTextArea
              name="notes"
              label="Dokumentasjon"
              onChange={updateAttribute("notes")}
              value={attributes.notes}
              size={12}
              disabled={locked}
            />
          </div>
        </Tab>

        <Tab id="documentation" tab={tab}>
          <div className="row">
            <TextArea
              name="documentation"
              label="Organisasjon"
              onChange={updateAttribute("documentation")}
              value={attributes.documentation}
              size={12}
              disabled={locked}
            />
          </div>
        </Tab>

        <Tab id="activities" tab={tab}>
          <div className="row">
            <TextArea
              name="activities"
              label="Notat"
              onChange={updateAttribute("activities")}
              value={attributes.activities}
              size={12}
              disabled={locked}
            />
          </div>
        </Tab>

        <Tab id="recommendation" tab={tab}>
          <div className="row">
            <TextArea
              name="recommendation"
              label="Saksbehandlers anbefaling"
              onChange={updateAttribute("recommendation")}
              value={attributes.recommendation}
              size={12}
              disabled={locked}
            />
          </div>
        </Tab>

        <Tab id="proposed" tab={tab}>
          <div className="row">
            <TextField
              name="total_cost"
              label="Kostnadsramme"
              onChange={updateAttribute("total_cost")}
              value={attributes.total_cost}
              size={4}
              validations={["decimal"]}
              disabled={locked}
            />
            <TextField
              name="grant_sum"
              label="Tilskuddsramme"
              onChange={updateAttribute("grant_sum")}
              value={attributes.grant_sum}
              size={4}
              validations={["decimal"]}
              disabled={locked}
            />
            <TextField
              name="deductible"
              label="Egenandel (%)"
              onChange={updateAttribute("deductible")}
              value={attributes.deductible}
              size={4}
              validations={["decimal"]}
              disabled={locked}
            />
          </div>
          <div className="row">
            <TextArea
              name="proposed_decision"
              label="Forslag til vedtak"
              onChange={updateAttribute("proposed_decision")}
              value={attributes.proposed_decision}
              size={12}
              disabled={locked}
            />
          </div>
          <div className="row">
            <TextArea
              name="summary"
              label="Tilskudd til (for tilskuddsliste)"
              onChange={updateAttribute("summary")}
              value={attributes.summary}
              size={12}
              disabled={locked}
            />
          </div>
        </Tab>

        <Tab id="reviews" tab={tab}>
          {reviews.length == 0 && <p>Ingen vurderinger er lagt inn enda.</p>}

          {reviews.map((r) => (
            <div key={r.id} className="review">
              <div className="row">
                <TextField
                  name="consultant_name"
                  label="Konsulent"
                  value={r.consultant_name}
                  size={12}
                  disabled={true}
                />
              </div>
              <div className="row">
                <TextArea
                  name="body"
                  label="Vurdering"
                  value={r.body}
                  size={12}
                  disabled={true}
                />
              </div>
            </div>
          ))}
        </Tab>

        <Tab id="previous_decisions" tab={tab}>
          <div className="row">
            <TextArea
              name="previous_decisions"
              label="Tidligere vedtak"
              onChange={updateAttribute("previous_decisions")}
              value={attributes.previous_decisions}
              size={12}
              disabled={locked}
            />
          </div>
        </Tab>

        <Tab id="excerpt" tab={tab}>
          <div className="row">
            <RichTextArea
              name="excerpt"
              label="Kopi av søknad"
              onChange={updateAttribute("excerpt")}
              value={attributes.excerpt}
              size={12}
              disabled={locked}
            />
          </div>
        </Tab>

        <Tab id="priority" tab={tab}>
          <h3>Søknadens prioritet</h3>
          <div className="row">
            <Radio
              name="priority"
              disabled={locked}
              options={props.priorities.map(stringOption)}
              value={`${attributes.priority}`}
              onChange={updateAttribute("priority")}
            />
          </div>

          <h3>Lås innstilling</h3>
          <div className="row">
            <CheckBox
              disabled={locked}
              name="locked"
              label="Innstilling låst"
              value={attributes.locked}
              onChange={updateAttribute("locked")}
            />
          </div>

          <div className="buttons">
            <button
              onClick={handleDelete}
              disabled={!props.deleteRecommendation}>
              <i className="material-icons">delete</i>
              Slett innstilling
            </button>
          </div>
        </Tab>
      </div>
    </section>
  );
}
