import React from "react";
import { EditorState, RichUtils } from "draft-js";

interface BlockTypeButtonProps {
  onChange: (state: EditorState) => void;
  editorState: EditorState;
  icon: string;
  title: string;
  className: string;
  type: string;
  disabled: boolean;
}

export default function BlockTypeButton(props: BlockTypeButtonProps) {
  const { icon, title, className, editorState } = props;

  const preventClick = (evt: React.MouseEvent) => evt.preventDefault();

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    props.onChange(RichUtils.toggleBlockType(props.editorState, props.type));
  };

  const isActive = () => {
    const currentType = editorState
      .getCurrentContent()
      .getBlockForKey(editorState.getSelection().getStartKey())
      .getType();
    return props.type === currentType;
  };

  const classNames = [className];
  if (isActive()) {
    classNames.push("active");
  }

  return (
    <button
      className={classNames.join(" ")}
      disabled={props.disabled}
      title={title}
      onMouseDown={handleClick}
      onClick={preventClick}>
      <i className="material-icons">{icon}</i>
    </button>
  );
}
