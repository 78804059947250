import React from "react";

import Item from "./CaseStatusList/Item";

interface Props {
  adminView: boolean;
  status_options?: CaseStatusOption[];
  case_statuses: Array<CaseMessage | CaseNote>;
}

export default function CaseStatusList(props: Props) {
  const { adminView, case_statuses, status_options } = props;

  return (
    <div className="case-status-list">
      {case_statuses.map((status) => (
        <Item
          key={status.id}
          adminView={adminView}
          status={status}
          status_options={status_options}
        />
      ))}
    </div>
  );
}
