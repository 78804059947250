import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Attachment, attachmentPath } from "../../lib/attachments";
import { csrfToken } from "../../lib/request";

const defaultFileTypeValidations = {
  extensions: [
    "csv",
    "gif",
    "jpg",
    "jpeg",
    "png",
    "pdf",
    "txt",
    "rtf",
    "doc",
    "docx",
    "ppt",
    "pptx",
    "xls",
    "xlsx"
  ],
  mimeTypes: [
    "image/gif",
    "image/jpeg",
    "image/pjpeg",
    "image/png",
    "text/plain",
    "application/pdf",
    "application/rtf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ]
};

function clearItems(dt: DataTransfer) {
  if (dt.items) {
    for (let i = 0; i < dt.items.length; i++) {
      dt.items.remove(i);
    }
  } else {
    dt.clearData();
  }
}

function getFiles(dt: DataTransfer): File[] {
  const files: File[] = [];
  if (dt.items) {
    for (let i = 0; i < dt.items.length; i++) {
      if (dt.items[i].kind == "file") {
        files.push(dt.items[i].getAsFile());
      }
    }
  } else {
    for (let i = 0; i < dt.files.length; i++) {
      files.push(dt.files[i]);
    }
  }
  return files;
}

function validFileType(file: File, validations: FormInput.FileTypeValidations) {
  const extension = file.name.split(".").reverse()[0].toLowerCase();

  return (
    validations.extensions.indexOf(extension) != -1 ||
    validations.mimeTypes.indexOf(file.type) != -1
  );
}

export default function FileUploader(props: FormInput.FileUploader) {
  const { disabled, files, limit, name, onChange, readonly } = props;
  const size = props.size || 9;

  const defaultInputRef = useRef<HTMLElement>();
  const fileRef = useRef<HTMLInputElement>();

  const inputRef = props.inputRef || defaultInputRef;

  const [dragover, setDragover] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [uploading, setUploading] = useState<File[]>([]);
  const [invalid, setInvalid] = useState<File[]>([]);

  const fileTypes = props.fileTypes || defaultFileTypeValidations;

  useEffect(() => {
    if (!showButtons) {
      setShowButtons(true);
    }
  }, [showButtons]);

  const browseFiles = (evt: React.MouseEvent) => {
    evt.preventDefault();
    fileRef.current.click();
  };

  const handleDragOver = (evt: React.DragEvent) => {
    evt.preventDefault();
    if (disabled || readonly || dragover) {
      return;
    }
    setDragover(true);
  };

  const handleDragLeave = () => {
    if (disabled || readonly) {
      return;
    }
    setDragover(false);
  };

  const handleDragEnd = (evt: React.DragEvent) => {
    if (disabled || readonly) {
      return;
    }
    if ("dataTransfer" in evt) {
      clearItems(evt.dataTransfer);
    }
    setDragover(false);
  };

  const handleDrop = (evt: React.DragEvent) => {
    if (disabled || readonly) {
      return;
    }
    if ("dataTransfer" in evt) {
      const files = getFiles(evt.dataTransfer);
      uploadFiles(files);
    }
    evt.preventDefault();
  };

  const removeFile = (evt: React.MouseEvent, file: Attachment) => {
    evt.preventDefault();
    if (confirm("Er du sikker på at du vil fjerne denne filen?")) {
      onChange((files: Attachment[]) => {
        return (files || []).filter((f) => f != file);
      });
    }
  };

  const removeInvalid = (evt: React.MouseEvent, file: File) => {
    evt.preventDefault();
    setInvalid(invalid.filter((f) => f !== file));
  };

  const showDropTarget = () => {
    if (limit && files.length + uploading.length >= limit) {
      return false;
    }
    return !disabled && !readonly;
  };

  const uploadSelectedFiles = (evt: ChangeEvent<HTMLInputElement>) => {
    const files: File[] = [];
    if ("files" in evt.target) {
      for (let i = 0; i < evt.target.files.length; i++) {
        files.push(evt.target.files[i]);
      }
    }
    uploadFiles(files);
  };

  const uploadFiles = async (newFiles: File[]) => {
    const validFiles = newFiles.filter((f) => validFileType(f, fileTypes));
    const invalidFiles = newFiles.filter((f) => !validFileType(f, fileTypes));

    setDragover(false);
    setUploading(uploading.concat(validFiles));
    setInvalid(invalid.concat(invalidFiles));

    const getCaseId = (): Promise<number> => {
      if (typeof props.caseId === "function") {
        return props.caseId();
      } else {
        return new Promise((r) => r(props.caseId as number));
      }
    };

    const caseId = await getCaseId();

    validFiles.forEach((f) => uploadFile(caseId, f));
  };

  const handleAdd = (file: Attachment) => {
    onChange((files: Attachment[]) => {
      const newFiles = (files || []).concat(file);
      if (limit) {
        return newFiles.slice(0, limit);
      } else {
        return newFiles;
      }
    });
  };

  const uploadFile = async (caseId: number, file: File) => {
    const data = new FormData();
    data.append("case_file[file]", file);

    const response = await fetch(`/saker/${caseId}/vedlegg.json`, {
      method: "POST",
      body: data,
      headers: { "X-CSRF-Token": csrfToken() }
    });

    if (response.ok) {
      handleAdd(await response.json());
    } else {
      setInvalid(invalid.concat(file));
    }
    setUploading(uploading.filter((f) => f !== file));
  };

  const isUploading = uploading.length > 0;

  const classes = [];

  if (dragover) {
    classes.push("dragover");
  }

  if (isUploading) {
    classes.push("uploading");
  }

  if (readonly) {
    classes.push("readonly");
  }

  return (
    <div
      className={`file-uploader field size-${size} ` + classes.join(" ")}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDragEnd={handleDragEnd}
      onDrop={handleDrop}>
      {(files.length > 0 || invalid.length > 0 || isUploading) && (
        <ul className="files">
          {files.map((f) => (
            <li key={`file-${f.id}`}>
              <input type="hidden" name={name + "[][id]"} value={f.id} />
              <input
                type="hidden"
                name={name + "[][filename]"}
                value={f.filename}
              />
              <i className="material-icons">attachment</i>
              {f.filename}
              {showButtons && (
                <div className="file-buttons">
                  <a
                    href={attachmentPath(f)}
                    className="button"
                    target="_blank"
                    rel="noreferrer">
                    Last ned
                  </a>
                  {!disabled && !readonly && (
                    <button onClick={(evt) => removeFile(evt, f)}>Fjern</button>
                  )}
                </div>
              )}
            </li>
          ))}
          {invalid.map((f, i) => (
            <li key={`invalid-${i}`} className="invalid">
              <i className="material-icons">error</i>
              {f.name}
              <div className="error-message">
                Denne filtypen er desverre ikke støttet
                <button onClick={(evt) => removeInvalid(evt, f)}>OK</button>
              </div>
            </li>
          ))}
          {isUploading && (
            <li className="upload-indicator">
              <i className="material-icons">cloud_upload</i>
              <em>Laster opp...</em>
            </li>
          )}
        </ul>
      )}
      {showDropTarget() && (
        <div className="drop-target">
          <p>Dra og slipp filer her, eller</p>
          <p>
            <button
              ref={inputRef as React.RefObject<HTMLButtonElement>}
              onClick={browseFiles}>
              <i className="material-icons">cloud_upload</i>
              Velg en fil
            </button>
            <input
              type="file"
              onChange={uploadSelectedFiles}
              ref={fileRef}
              multiple
            />
          </p>
        </div>
      )}
    </div>
  );
}
