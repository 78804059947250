import React, { RefObject, useEffect, useRef } from "react";

interface HtmlDivProps {
  className: string;
  text: string;
}

function htmlize(str: string) {
  if (str.match(/</)) {
    return str;
  } else {
    return `<p>${str}</p>`;
  }
}

function useLinkTargets(ref: RefObject<HTMLDivElement>) {
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.querySelectorAll("a").forEach((link) => {
      if (link.getAttribute("target") !== "_blank") {
        link.setAttribute("target", "_blank");
      }
    });
  }, [ref]);
}

export default function HtmlDiv(props: HtmlDivProps) {
  const { className, text } = props;

  const ref = useRef<HTMLDivElement>(null);
  useLinkTargets(ref);

  if (!text) {
    return "";
  } else {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: htmlize(text) }}
        ref={ref}
      />
    );
  }
}
