import React from "react";

import Description from "./Description";

interface Props {
  form: Form.Form;
}

export default function Header(props: Props) {
  const { form } = props;
  return (
    <section className="intro">
      <div className="inner">
        <h1>{form.name}</h1>
        <Description text={form.description} />
      </div>
    </section>
  );
}
