import React from "react";
import CaseMessageItem from "./CaseMessageItem";
import CaseNoteItem from "./CaseNoteItem";

interface Props {
  adminView: boolean;
  status: CaseMessage | CaseNote;
  status_options: CaseStatusOption[];
}

export default function Item(props: Props) {
  const { adminView, status, status_options } = props;

  if (status.type == "CaseMessage") {
    return (
      <CaseMessageItem key={status.id} adminView={adminView} status={status} />
    );
  } else if (status.type == "CaseNote") {
    return (
      <CaseNoteItem
        key={status.id}
        adminView={adminView}
        status_options={status_options}
        status={status}
      />
    );
  } else {
    return "";
  }
}
