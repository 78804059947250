import React from "react";

interface TabLinkProps {
  tab: Recommendation.InterfaceTab;
  onClick: (evt: React.MouseEvent) => void;
  current: boolean;
}

export default function TabLink(props: TabLinkProps) {
  const classes = ["nav-link"];
  if (props.current) {
    classes.push("current");
  }

  return (
    <a
      className={classes.join(" ")}
      onClick={props.onClick}
      href={`#${props.tab.id}`}>
      {props.tab.label}
    </a>
  );
}
