import React from "react";

import { csrfToken } from "../../lib/request";
import { SimpleSelect, stringOption } from "../FormInput";

interface Props {
  case_processors: CaseProcessorOption[];
  cases: Cases.Case[];
  filteredCases: Cases.Case[];
  selected: Cases.Case[];
  status_options: CaseStatusOption[];
  updateCases: (cases: Cases.Case[]) => void;
  updateSelection: (cases: Cases.Case[]) => void;
}

interface BackendResponse {
  cases: Cases.Case[];
}

interface BulkUpdateOptions {
  case_processor_id?: number;
  status?: string;
}

export default function Actions(props: Props) {
  const {
    cases,
    case_processors,
    filteredCases,
    status_options,
    selected,
    updateCases,
    updateSelection
  } = props;

  const exportCases = selected.length > 0 ? selected : filteredCases;
  const selectUnchecked = selected.length === 0;

  const lockDecisions = () => {
    bulkPost("/saksbehandler/saker/lock_decisions.json", {
      ids: selected.map((c) => c.id)
    });
  };

  const lockRecommendations = () => {
    bulkPost("/saksbehandler/saker/lock_recommendations.json", {
      ids: selected.map((c) => c.id)
    });
  };

  const bulkPost = (url: string, data) => {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.setRequestHeader("X-CSRF-Token", csrfToken());
    xhr.onload = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        const response = JSON.parse(xhr.responseText) as BackendResponse;
        updateCases(response.cases);
      }
    };
    xhr.send(JSON.stringify(data));
  };

  const bulkUpdate = (attrs: BulkUpdateOptions) => {
    bulkPost("/saksbehandler/saker/bulk_update.json", {
      ids: selected.map((c) => c.id),
      case_note: attrs
    });
  };

  const changeArchived = (archivedStatus) => {
    if (archivedStatus) {
      bulkPost("/saksbehandler/saker/bulk_archive.json", {
        ids: selected.map((c) => c.id),
        case: { archived: archivedStatus === "archived" }
      });
    }
  };

  const changeCaseProcessor = (id?: string) => {
    if (id) {
      bulkUpdate({ case_processor_id: parseInt(id) });
    }
  };

  const changeStatus = (status: string) => {
    if (status) {
      bulkUpdate({ status: status });
    }
  };

  const allSelected = () => {
    return cases.filter((c) => !selected.includes(c)).length == 0;
  };

  const toggleSelected = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (selected.length === 0) {
      updateSelection(cases);
    } else {
      updateSelection([]);
    }
  };

  const selectToggleIcon = () => {
    if (allSelected()) {
      return "check_box";
    } else if (selected.length > 0) {
      return "indeterminate_check_box";
    } else {
      return "check_box_outline_blank";
    }
  };

  return (
    <div className="case-list-actions">
      <a href="#" onClick={toggleSelected}>
        <i
          className={
            "material-icons check-box " + (selectUnchecked ? "unchecked" : "")
          }>
          {selectToggleIcon()}
        </i>
      </a>
      <SimpleSelect
        name="case_processor"
        inlineLabel="Endre saksbehandler"
        options={case_processors.map(stringOption)}
        value=""
        disabled={selected.length === 0}
        onChange={changeCaseProcessor}
      />
      <SimpleSelect
        name="status"
        inlineLabel="Endre status"
        options={status_options}
        value=""
        disabled={selected.length === 0}
        onChange={changeStatus}
      />
      <SimpleSelect
        name="archived"
        inlineLabel="Endre åpen/arkivert"
        options={[
          ["Åpen", "open"],
          ["Arkivert", "archived"]
        ]}
        value=""
        disabled={selected.length === 0}
        onChange={changeArchived}
      />
      <button disabled={selected.length === 0} onClick={lockRecommendations}>
        Lås innstillinger
      </button>
      <button disabled={selected.length === 0} onClick={lockDecisions}>
        Lås vedtak
      </button>
      <form
        action="/saksbehandler/saker/export.xlsx"
        method="POST"
        className="export-form">
        <input name="authenticity_token" type="hidden" value={csrfToken()} />
        <input
          name="ids"
          type="hidden"
          value={exportCases.map((c) => `${c.id}`)}
        />
        <button className="export" type="submit">
          Eksporter til Excel ({exportCases.length})
        </button>
      </form>
    </div>
  );
}
