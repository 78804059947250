import React, { ChangeEvent } from "react";

import Label from "./Label";
import { optionLabel, optionValue } from "./options";
import useInput, { nameToId } from "./useInput";

export default function SimpleSelect(props: FormInput.SimpleSelect) {
  const { name, blankOption, inlineLabel, label, options } = props;

  const { value, setValue } = useInput<FormInput.SimpleSelect>(props);

  const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    setValue(evt.target.value);
  };

  return (
    <div className="simple-select">
      {label && <Label htmlFor={nameToId(name)} label={label} />}
      <select
        onChange={handleChange}
        id={nameToId(name)}
        name={name}
        value={value}
        disabled={props.disabled || false}>
        {!blankOption && inlineLabel && (
          <option key="blank" value={value}>
            {inlineLabel}
          </option>
        )}
        {blankOption && (
          <option key="blank" value="">
            {blankOption}
          </option>
        )}
        {options.map((o, i) => (
          <option key={i} value={optionValue(o)}>
            {optionLabel(o)}
          </option>
        ))}
      </select>
    </div>
  );
}
