import React from "react";

import { CaseFormState, CaseFormDispatch } from "./useCaseForm";
import Block from "./Block";
import Buttons from "./Buttons";
import Description from "./Description";
import ValidationErrors from "./ValidationErrors";
import { invalidFields } from "./validations";
import { saveUrl } from "./persistence";
import { csrfToken } from "../../lib/request";

interface Props {
  tab: Form.Tab;
  state: CaseFormState;
  dispatch: CaseFormDispatch;
  preview: boolean;
}

export default function Tab(props: Props) {
  const { tab, state, dispatch } = props;

  const invalid = invalidFields(state);

  return (
    <section>
      <div className="inner">
        <form method="post" action={saveUrl(state)}>
          <input name="utf8" type="hidden" value="✓" />
          <input
            type="hidden"
            name="_method"
            value={state.id ? "patch" : "post"}
          />
          <input type="hidden" name="authenticity_token" value={csrfToken()} />
          {tab.submit && (
            <ValidationErrors fields={invalid} dispatch={dispatch} />
          )}
          <h2 className="border">{tab.name}</h2>
          {!state.summary && <Description text={tab.description} />}
          {tab.blocks.map((block, i) => (
            <Block
              state={state}
              dispatch={dispatch}
              block={block}
              readonly={state.summary}
              key={i}
            />
          ))}
          {!state.summary && !props.preview && (
            <Buttons
              state={state}
              dispatch={dispatch}
              invalid={invalid}
              preview={props.preview}
              tab={tab}
            />
          )}
        </form>
      </div>
    </section>
  );
}
