import React, { useState } from "react";

import { FileUploader, TextField } from "../FormInput";

interface Props {
  caseId: number;
  cost: Report.Cost;
  closeModal: (evt?: React.MouseEvent) => void;
  updateCost: (cost: Report.Cost) => void;
  receiptRequired: boolean;
  strings: Record<string, string>;
}

export default function CostForm(props: Props) {
  const { caseId, closeModal, updateCost, receiptRequired, strings } = props;

  const [state, setCost] = useState(props.cost);
  const [invoiceFiles, setInvoiceFiles] = useState<Attachment[]>(
    props.cost.invoice_files
  );
  const [receiptFiles, setReceiptFiles] = useState<Attachment[]>(
    props.cost.receipt_files
  );

  const updateState = (newState: Partial<Report.Cost>) => {
    setCost({ ...state, ...newState });
  };

  let valid = state.name && state.sum && invoiceFiles.length > 0;
  if (receiptRequired) {
    valid = valid && receiptFiles.length > 0;
  }

  const handleSave = (evt: React.MouseEvent) => {
    evt.preventDefault();
    if (valid) {
      updateCost({
        ...state,
        invoice_files: invoiceFiles,
        receipt_files: receiptFiles
      });
      closeModal();
    }
  };

  return (
    <form className="cost-form modal-form">
      <h2>Dokumentasjon</h2>
      <div className="description">{strings.costsDescription}</div>
      <div className="row">
        <TextField
          name="costName"
          label={strings.costName}
          onChange={(val: string) => updateState({ name: val })}
          value={state.name}
          size={8}
        />
        <TextField
          name="sum"
          label="Sum"
          onChange={(val: string) =>
            updateState({ sum: val ? parseInt(val) : null })
          }
          value={`${state.sum || ""}`}
          size={4}
          validations={["decimal"]}
        />
      </div>
      <h3>Spesifisert faktura</h3>
      <div className="description">
        <p>Last opp faktura på innkjøp (kun én fil).</p>
      </div>
      <div className="row">
        <FileUploader
          name="invoiceFiles"
          files={invoiceFiles}
          caseId={caseId}
          onChange={setInvoiceFiles}
          size={12}
          limit={1}
        />
      </div>
      <h3>Betalingsbekreftelse</h3>
      <div className="description">
        <p>
          Last opp kvittering(er) for betalt faktura.
          {!receiptRequired && " Feltet er ikke obligatorisk."}
        </p>
      </div>
      <div className="row">
        <FileUploader
          name="receiptFiles"
          files={receiptFiles}
          caseId={caseId}
          onChange={setReceiptFiles}
          size={12}
        />
      </div>
      <div className="buttons">
        <p>
          Alle felter må fylles ut og det må lastes opp vedlegg før knappen
          «Oppdater» aktiveres.
        </p>
        <button type="submit" disabled={!valid} onClick={handleSave}>
          Oppdater
        </button>
        <button onClick={closeModal}>Avbryt</button>
      </div>
    </form>
  );
}
