import React, { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";

interface ModalProps {
  children: ReactNode;
  onClose?: () => void;
}

export default function Modal(props: ModalProps) {
  const el = document.createElement("div");
  el.classList.add("modal-wrapper");

  const close = (evt: React.MouseEvent | KeyboardEvent) => {
    if (evt) {
      evt.preventDefault();
      evt.stopPropagation();
    }
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleKeypress = (evt: KeyboardEvent) => {
    if (evt.which == 27) {
      close(evt);
    }
  };

  useEffect(() => {
    document.body.appendChild(el);
    document.addEventListener("keydown", handleKeypress);
    return () => {
      document.body.removeChild(el);
      document.removeEventListener("keydown", handleKeypress);
    };
  });

  return createPortal(
    <>
      <div className="modal-background" onClick={close} />
      <div className="inner">
        <div className="modal-content">{props.children}</div>
      </div>
    </>,
    el
  );
}
