import React from "react";

import Item from "./Item";

interface Props {
  cases: Cases.Case[];
  forms: Cases.Form[];
  status_options: CaseStatusOption[];
  sort: Cases.Sort;
  updateSelection: (selected: Cases.Case[]) => void;
  updateSort: (newSort: Cases.Sort) => void;
  selected: Cases.Case[];
}

export default function List(props: Props) {
  const {
    cases,
    forms,
    status_options,
    updateSelection,
    selected,
    sort,
    updateSort
  } = props;

  const sortColumn = (label: string, column: Cases.SortColumn) => {
    const classes = ["sortable"];
    if (sort.column == column) {
      classes.push("current");
      if (sort.reverse) {
        classes.push("reverse");
      }
    }
    return (
      <th className={classes.join(" ")}>
        <a href="#" onClick={(evt) => toggleSort(column, evt)}>
          {label}
        </a>
      </th>
    );
  };

  const toggleSort = (column: Cases.SortColumn, evt: React.MouseEvent) => {
    evt.preventDefault();
    let reverse = false;
    if (sort.column == column) {
      reverse = !sort.reverse;
    }
    updateSort({ column: column, reverse: reverse });
  };

  return (
    <div className="table-wrapper">
      <table className="cases list linked-rows flaggable">
        <thead>
          <tr>
            {updateSelection && <th></th>}
            {sortColumn("Saksnummer", "submitted_at")}
            {sortColumn("Søker", "organization_name")}
            {sortColumn("Ordning", "form_name")}
            {sortColumn("Innstilling", "process_status")}
            {sortColumn("Status", "status")}
            {sortColumn("Sist aktiv", "updated_at")}
            {sortColumn("Ulest", "unread_messages")}
          </tr>
        </thead>
        <tbody>
          {cases.map((c) => (
            <Item
              key={c.id}
              case={c}
              forms={forms}
              selected={selected}
              status_options={status_options}
              updateSelection={updateSelection}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
