import React from "react";

import HtmlDiv from "../HtmlDiv";

interface DescriptionProps {
  text: string;
}

export default function Description(props: DescriptionProps) {
  return <HtmlDiv className="description" {...props} />;
}
