import React from "react";
import ReactPaginate from "react-paginate";

interface PaginationProps {
  hrefBuilder: () => void;
  onPageChange: (selectedItem: { selected: number }) => void;
  page: number;
  pageCount: number;
}

export default function Pagination(props: PaginationProps) {
  const { hrefBuilder, onPageChange, page, pageCount } = props;

  return (
    <ReactPaginate
      hrefBuilder={hrefBuilder}
      forcePage={page - 1}
      disableInitialCallback={true}
      onPageChange={onPageChange}
      pageCount={pageCount}
      pageRangeDisplayed={7}
      marginPagesDisplayed={1}
      nextLabel="Neste side &#8594;"
      previousLabel="&#8592; Forrige side"
      activeClassName="current"
      containerClassName="pagination"
    />
  );
}
