import React, { ChangeEvent } from "react";

import Error from "./Error";
import Field from "./Field";
import Label from "./Label";
import { optionLabel, optionValue } from "./options";
import useInput, { nameToId } from "./useInput";

export default function Select(props: FormInput.Select) {
  const { label, name, options } = props;

  const { value, setValue, listeners, error, focus } =
    useInput<FormInput.Select>(props);

  const handleChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    setValue(evt.target.value);
  };

  return (
    <Field type="select" size={props.size}>
      {label && (
        <Label
          htmlFor={nameToId(name)}
          label={`${label}:`}
          tooltip={props.tooltip}
        />
      )}
      <div
        className={
          "select-wrapper " +
          (focus ? "focus " : "") +
          (props.disabled ? "disabled" : "")
        }>
        <select
          id={nameToId(name)}
          name={name}
          value={value}
          disabled={props.disabled || false}
          onChange={handleChange}
          {...listeners}
          ref={listeners.ref as React.RefObject<HTMLSelectElement>}>
          {props.blankOption && (
            <option key="blank" value="">
              {props.blankOption}
            </option>
          )}
          {options.map((o, i) => (
            <option key={i} value={optionValue(o)}>
              {optionLabel(o)}
            </option>
          ))}
        </select>
      </div>
      <Error error={error} />
    </Field>
  );
}
