import React from "react";
import Tippy from "@tippyjs/react";

import HtmlDiv from "../HtmlDiv";

import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away-subtle.css";

interface TooltipProps {
  tooltip: string;
}

export default function Tooltip(props: TooltipProps) {
  const { tooltip } = props;

  const content = <HtmlDiv className="tooltip-content" text={tooltip} />;

  return (
    <Tippy
      animation="shift-away-subtle"
      allowHTML={true}
      appendTo={() => document.body}
      content={content}
      interactive={true}
      inertia={false}
      maxWidth={380}
      placement="bottom"
      trigger="click">
      <button className="tooltip-button" type="button">
        <i className="material-icons">info</i>
      </button>
    </Tippy>
  );
}
