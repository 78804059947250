import React, { useEffect } from "react";

import Header from "./CaseForm/Header";
import Form from "./CaseForm/Form";
import useCaseForm, {
  CaseFormState,
  CaseFormDispatch
} from "./CaseForm/useCaseForm";
import { tabPath } from "./CaseForm/persistence";

interface Props {
  form: Form.Form;
  data: Form.Data;
  tab_id: string;
  id: number;
  preview: boolean;
  submitted: boolean;
  summary: boolean;
}

interface HistoryState {
  currentTab: number;
}

function useCaseFormLocation(
  state: CaseFormState,
  dispatch: CaseFormDispatch,
  preview: boolean
) {
  const setStateFromHistory = (evt: PopStateEvent) => {
    const evtState = evt.state as HistoryState;
    if (typeof evtState === "object" && evtState !== null) {
      evt.preventDefault();
      dispatch({ type: "changeTab", payload: evtState.currentTab });
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", setStateFromHistory);
    return () => window.removeEventListener("popstate", setStateFromHistory);
  });

  useEffect(() => {
    const path = tabPath(state, state.form.tabs[state.currentTab], preview);
    if (document.location.pathname != path && !state.summary && !preview) {
      history.pushState({ currentTab: state.currentTab }, "", path);
    }
  }, [preview, state]);
}

export default function CaseForm(props: Props) {
  const [state, dispatch] = useCaseForm(props);
  const { form, focusField } = state;

  useCaseFormLocation(state, dispatch, props.preview);

  useEffect(() => {
    if (focusField && focusField.ref.current) {
      focusField.ref.current.focus();
      dispatch({ type: "clearFocusField" });
    }
  }, [focusField, dispatch]);

  return (
    <div className="case-form">
      {!state.summary && <Header form={form} />}
      <Form state={state} dispatch={dispatch} preview={props.preview} />
    </div>
  );
}
