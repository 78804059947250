import React, { useMemo, useState } from "react";
import { RawDraftContentState, EditorState, convertFromRaw } from "draft-js";

import { Select, RichTextArea, TextField } from "./FormInput";

interface Props {
  agreement: Agreement;
  templates: AgreementTemplate<Draft.RawDraftContentState>[];
  locked: boolean;
}

function findTemplate(id: number, templates: AgreementTemplate[]) {
  return id && templates.filter((t) => t.id == id)[0];
}

function createEditorState(value: RawDraftContentState | null) {
  if (value && Object.keys(value).length > 0) {
    return EditorState.createWithContent(convertFromRaw(value));
  } else {
    return EditorState.createEmpty();
  }
}

function prepareTemplates(
  templates: AgreementTemplate<Draft.RawDraftContentState>[]
): AgreementTemplate<Draft.EditorState>[] {
  return templates.map((tpl) => {
    return {
      ...tpl,
      body: createEditorState(tpl.body),
      additional_info: createEditorState(tpl.additional_info)
    };
  });
}

export default function AgreementForm(props: Props) {
  const templates = useMemo(
    () => prepareTemplates(props.templates),
    [props.templates]
  );
  const { agreement, locked } = props;
  const [template, setTemplate] = useState<number>(null);
  const [name, setName] = useState(agreement.name || "");
  const [body, setBody] = useState(() => createEditorState(agreement.body));
  const [additionalInfo, setAdditionalInfo] = useState(() =>
    createEditorState(agreement.additional_info)
  );

  const templateOptions: FormInput.Option[] = templates.map((t) => [
    t.name,
    `${t.id}`
  ]);

  const changeTemplate = (tpl?: AgreementTemplate) => {
    if (tpl) {
      setTemplate(tpl.id);
      setName(tpl.name);
      setBody(tpl.body);
      setAdditionalInfo(tpl.additional_info);
    } else {
      setTemplate(null);
    }
  };

  const handleTemplateChange = (idStr: string) => {
    const prevTemplate = findTemplate(template, templates);
    const nextTemplate = findTemplate(parseInt(idStr), templates);

    if (
      (body.getCurrentContent().hasText() && body !== prevTemplate.body) ||
      (additionalInfo.getCurrentContent().hasText() &&
        additionalInfo !== prevTemplate.additional_info) ||
      (name !== "" && name !== prevTemplate.name)
    ) {
      if (
        confirm(
          "Du har gjort endringer i avtalen. Sikker på at du vil bytte mal?"
        )
      ) {
        changeTemplate(nextTemplate);
      }
    } else {
      changeTemplate(nextTemplate);
    }
  };

  return (
    <div className="agreement-form">
      {!locked && (
        <div className="row">
          <Select
            name="agreement[template]"
            label="Mal"
            blankOption="Velg mal"
            options={templateOptions}
            onChange={handleTemplateChange}
            disabled={locked}
            size={4}
            value={`${template || ""}`}
          />
        </div>
      )}
      <div className="row">
        <TextField
          name="agreement[name]"
          label="Navn"
          onChange={(val: string) => setName(val)}
          disabled={locked}
          value={name}
          size={12}
        />
      </div>
      <div className="row">
        <RichTextArea
          name="agreement[body]"
          label="Avtale"
          onChange={(val: EditorState) => setBody(val)}
          simple={false}
          disabled={locked}
          value={body}
          size={12}
        />
      </div>
      <div className="row">
        <RichTextArea
          name="agreement[additional_info]"
          label="Ekstra informasjon"
          onChange={(val: EditorState) => setAdditionalInfo(val)}
          simple={false}
          disabled={locked}
          value={additionalInfo}
          size={12}
        />
      </div>
    </div>
  );
}
