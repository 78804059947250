import React from "react";

const strings = {
  AdvanceReport: {
    formName: "Forskudd",
    intro: (
      <React.Fragment>
        <p>
          For å få utbetalt et forskudd må utstyret være satt i bestilling.
          Spesifisert faktura eller ordrebekreftelse fra leverandør legges til
          grunn ved beregning av forskudd. Kulturrom kan utbetale inntil 75 % av
          tilskuddet som forskudd. Resterende tilskudd utbetales ved godkjent
          sluttrapport.
          <br />
          Sluttrapport skal sendes inn senest 3 måneder etter utbetaling av
          forskudd.
        </p>
        <p>
          Les mer om forskudd og rapportering her:{" "}
          <a
            href="https://kulturrom.no/fatt-tilskudd/"
            target="_blank"
            rel="noreferrer">
            kulturrom.no/fatt-tilskudd/
          </a>
        </p>
      </React.Fragment>
    ),
    implementationDescription: (
      <p>
        Beskriv hvor langt dere har kommet i gjennomføringen av tiltaket, og hva
        som eventuelt gjenstår. Relevante opplysninger kan også være: Har
        gjennomføringen gått som planlagt?
      </p>
    ),
    costsHeading: "Dokumentasjon på bruk av forskudd",
    costsDescription: (
      <p>
        For å dokumentere hva forskuddet skal brukes til må det legges ved
        spesifisert faktura eller ordrebekreftelse fra leverandør. Hver faktura
        skal legges inn enkeltvis.
      </p>
    ),
    costName: "Oppgi hva som er satt i bestilling/kjøpt inn",
    paymentDescription: <p>Forskudd utbetales kun til tilskuddsmottaker.</p>,
    accountNumber: "Forskudd utbetales til kontonummer",
    confirmReportPower:
      "Jeg bekrefter med dette at jeg har nødvendig " +
      "fullmakt til å søke om forskudd på vegne av " +
      "Tilskuddsmottaker.",
    confirmOtherFinancing:
      "Jeg bekrefter at vi ikke har mottatt " +
      "finansiering til samme deler av tiltaket fra andre statlig " +
      "finansierte ordninger."
  },
  FinalReport: {
    formName: "Sluttrapport",
    intro: (
      <p>
        Sluttrapport skal sendes inn når tiltaket er ferdigstilt og alle
        kostnader i forbindelse med gjennomføring kan dokumenteres.
        <br />
        Les mer om rapportering her:{" "}
        <a
          href="https://kulturrom.no/fatt-tilskudd/"
          target="_blank"
          rel="noreferrer">
          kulturrom.no/fatt-tilskudd/
        </a>
      </p>
    ),
    implementationDescription: (
      <p>
        Beskriv gjennomføringen av tiltaket. Relevante opplysninger kan være:
        Hva er tilskuddet konkret brukt til? Har gjennomføringen gått som
        planlagt?
      </p>
    ),
    costsHeading: "Dokumentasjon på bruk av tilskudd",
    costsDescription: (
      <p>
        Bruken av tilskuddet skal dokumenteres med faktura og
        betalingsbekreftelse. Faktura skal være spesifisert og vise hva som er
        kjøpt inn. Hver faktura skal legges inn enkeltvis. Betalingsbekreftelse
        skal dokumentere kontonummer, kontoeier og at betalingen er gjennomført.
        Det skal rapporteres for hele investeringen inkludert egenandel.
      </p>
    ),
    costName: "Oppgi hva som er kjøpt inn",
    paymentDescription: <p>Tilskudd utbetales kun til tilskuddsmottaker.</p>,
    accountNumber: "Tilskudd utbetales til kontonummer",
    confirmReportPower:
      "Jeg bekrefter med dette at jeg har nødvendig " +
      "fullmakt til å rapportere på vegne av " +
      "Tilskuddsmottaker.",
    confirmOtherFinancing:
      "Jeg bekrefter at vi ikke har mottatt " +
      "finansiering til samme deler av tiltaket, som " +
      "det rapporteres for her, fra andre statlig " +
      "finansierte ordninger."
  }
};

export default function content(type: string) {
  return strings[type] as Record<string, string>;
}
