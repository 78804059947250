import React, { ChangeEvent } from "react";

import Error from "./Error";
import Field from "./Field";
import Label from "./Label";
import useInput, {
  formattedValue,
  nameToId,
  textFieldInitializer
} from "./useInput";

export default function Calculation(props: FormInput.Calculation) {
  const { description, label, name, validations } = props;

  const { value, setValue, listeners, error } = useInput<FormInput.Calculation>(
    props,
    textFieldInitializer(props)
  );

  const handleChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
  };

  return (
    <Field type="text calculated" size={props.size}>
      <Label
        htmlFor={nameToId(name)}
        label={`${label}:`}
        tooltip={props.tooltip}
      />
      <input
        id={nameToId(name)}
        disabled={props.disabled}
        placeholder={props.placeholder}
        type="text"
        name={name}
        className={error ? "warn" : ""}
        value={formattedValue(value, validations)}
        onChange={handleChange}
        readOnly={props.readonly}
        {...listeners}
        ref={listeners.ref as React.RefObject<HTMLInputElement>}
      />
      <Error error={error} />
      {description && !error && (
        <div className="description">{description}</div>
      )}
    </Field>
  );
}
