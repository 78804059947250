import React from "react";
import { EditorState, RichUtils } from "draft-js";

interface InlineStyleButtonProps {
  onChange: (state: EditorState) => void;
  editorState: EditorState;
  style: string;
  icon: string;
  title: string;
  className?: string;
  disabled: boolean;
}

export default function InlineStyleButton(props: InlineStyleButtonProps) {
  const { title, editorState } = props;

  const preventClick = (evt: React.MouseEvent) => evt.preventDefault();

  const style = props.style || title.toUpperCase();
  const classNames = [props.className || style.toLowerCase()];
  const icon = props.icon || "format_" + style.toLowerCase();

  if (editorState.getCurrentInlineStyle().has(style)) {
    classNames.push("active");
  }

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    props.onChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  return (
    <button
      className={classNames.join(" ")}
      title={title}
      disabled={props.disabled}
      onMouseDown={handleClick}
      onClick={preventClick}>
      <i className="material-icons">{icon}</i>
    </button>
  );
}
