import React from "react";
import RelativeTime from "../RelativeTime";
import CaseIcon from "../CaseIcon";
import { formatCompactDateTime } from "../../lib/dates";

interface Props {
  case: Cases.Case;
  forms: Cases.Form[];
  status_options: CaseStatusOption[];
  selected: Cases.Case[];
  updateSelection: (selected: Cases.Case[]) => void;
}

function formatNumber(num: number) {
  return new Intl.NumberFormat("nb-NO", { style: "decimal" }).format(num);
}

export default function Case(props: Props) {
  const { forms, status_options, updateSelection } = props;

  const budget = formatNumber(props.case.total_budget);
  const timestamp = formatCompactDateTime(props.case.submitted_at);

  const caseProcessor = () => {
    const { case_processor } = props.case;
    if (case_processor) {
      return case_processor.name;
    } else {
      return "-";
    }
  };

  const isSelected = () => {
    return props.selected.indexOf(props.case) !== -1;
  };

  const toggleSelected = (evt: React.MouseEvent) => {
    const selected = props.selected;
    evt.preventDefault();
    if (isSelected()) {
      updateSelection(selected.filter((c) => c !== props.case));
    } else {
      updateSelection([props.case, ...selected]);
    }
  };

  const c = props.case;
  const formName = forms.filter((f) => f.slug == c.form_slug)[0].name;
  const statusName = status_options.filter((o) => o[1] == c.status)[0][0];
  return (
    <tr className={c.flagged ? "flagged" : ""}>
      {updateSelection && (
        <td>
          <a href={c.url} onClick={toggleSelected}>
            <i
              className={
                "material-icons check-box " + (isSelected() ? "" : "unchecked")
              }>
              {isSelected() ? "check_box" : "check_box_outline_blank"}
            </i>
          </a>
        </td>
      )}
      <td className="case-number">
        <a href={c.url}>
          {c.case_number}
          <br />
          {timestamp}
        </a>
      </td>
      <td>
        <a href={c.url}>
          <span className="organization-name">
            <b>{c.organization_name}</b>
          </span>
          <span className="organization-number">{c.organization_number}</span>
          <br />
          {c.contact_name}
        </a>
      </td>
      <td>
        <a href={c.url}>
          {formName}
          <br />
          {budget} kr
        </a>
      </td>
      <td className="status process-status">
        <a href={c.url}>
          {c.process_status && (
            <React.Fragment>
              {c.process_status.locked && (
                <i className="material-icons">lock</i>
              )}
              {!c.process_status.locked && (
                <i className="material-icons">lock_open</i>
              )}
              <b>{c.process_status.label}</b>
              <br />
              {c.process_status.sum && c.process_status.sum > 0 && (
                <React.Fragment>
                  {formatNumber(c.process_status.sum)} kr
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </a>
      </td>
      <td className="status case-status">
        <a href={c.url}>
          <CaseIcon case={c} />
          <b>{statusName}</b>
          <br />
          {caseProcessor()}
        </a>
      </td>
      <td className="relative-time">
        <a href={c.url}>
          <RelativeTime time={c.updated_at} />
        </a>
      </td>
      <td className="read-status">
        <a href={c.url}>
          {c.unread_messages > 0 && <span className="unread-marker" />}
        </a>
      </td>
    </tr>
  );
}
